import React from 'react';

const NotFoundPage: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <h1 className="text-4xl font-bold mb-4 text-center">
        404
        <br />
        Page Not Found
      </h1>
      <p className="text-lg mb-4">{`The page you're looking for doesn't exist.`}</p>
    </div>
  );
};

export default NotFoundPage;
